<template>
  <div class="auth">
    <header>
      <h1 class="flex flex-column align-items-center">
        <span class="display-3 pb-1" style="font-weight: 200">Application</span>
        <span class="text-4xl font-semibold uppercase">Inventaire</span>
      </h1>
      <div class="absolute bottom-0 right-0 left-0 p-2 text-center bg-warning">
        <span class="display-5">Accès administration</span>
      </div>
    </header>
    <main>
      <router-view></router-view>
    </main>
    <footer>
      <span>
        <router-link
          v-if="$route.name !== 'ForgottenPassword'"
          :to="{ name: 'ForgottenPassword' }"
          >Mot de passe oublié</router-link
        >
        <router-link v-if="$route.name !== 'Login'" :to="{ name: 'Login' }"
          >Se connecter</router-link
        >
        |
        <router-link :to="{ name: 'Register' }">Première connexion</router-link>
      </span>
    </footer>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.auth {
  display: grid;
  height: 100vh;
  grid-template-rows: 40vh auto 48px;
  header {
    background-image: linear-gradient(-45deg, $primary-dark, $primary);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  main {
    display: flex;
    justify-content: center;
    //align-items: center;
  }
  footer {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
  }
}
</style>
